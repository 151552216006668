@media only screen and (max-width: 650px) {
  .SaleTypeHeaderControl {
    display: none !important;
  }
}

@media screen and (max-width: 655px) {
  .ImageSquare {
    max-width: 65%;
  }
}
