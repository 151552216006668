/*
  workaround for issue in scrollbar library
  https://github.com/utatti/perfect-scrollbar/issues/792
*/
.ps__rail-y {
  left: auto !important;
}

/* force rails to always be visible, regardless of whether they are active */
.ps__rail-y {
  z-index: 2;
  opacity: 0.4;
}

.ps__rail-x {
  opacity: 0.4;
}

/*
  disable clunky hover effect for horizontal scrolling containers
  https://github.com/mdbootstrap/perfect-scrollbar/issues/820 
*/
.ps .ps__rail-x:hover,
.ps .ps--clicking {
  background: transparent !important;
  opacity: 0.6 !important;
}

.ps__thumb-x {
  height: 6px !important;
  background-color: #aaa !important;
}

.hide_x_rail .ps__rail-x {
  display: none;
}
