.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  background-color: 'transparent';

  /* box-shadow: 0 1px 3px 0 #e6ebf1; */
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

#payment-request-button .StripeElement {
  padding: 0;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

#cvc-helper {
  display: none;
}

@media only screen and (max-width: 375px) {
  #cvc-helper {
    display: inline;
  }
}
